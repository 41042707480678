import React from 'react';
import AboutLanding from './AboutLanding';
import AboutDetails from './AboutDetails';

const About = () => {
    return (
        <div>
           <div class=" ">
            <AboutLanding></AboutLanding>
            <AboutDetails></AboutDetails>
    
      
    
</div>
 
        </div>
    );
};

export default About;