import React from "react";

const EducationalUSA = () => {
  return (
    <div>
      <div class="relative flex min-h-screen flex-col justify-center overflow-hidden   sm:py-12">
        <div class="w-full items-center mx-auto max-w-screen-lg">
          <div class="group grid w-full sm:grid-cols-1 lg:grid-cols-2">
            <div>
              <div class="pl-12">
                <div class="p-4 max-w-xl mx-auto">
                  <div class="flex">
                    <div class="mr-4 flex flex-col items-center">
                      <div>
                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="h-6 w-6 text-white dark:text-slate-200"
                          >
                            <path d="M12 5l0 14"></path>
                            <path d="M18 13l-6 6"></path>
                            <path d="M6 13l6 6"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                    </div>
                    <div class="pt-1 pb-8 ">
                      <p class="mb-2 text-xl font-bold text-white dark:text-dark">
                        Intakes:
                      </p>
                      <ul class="mt-2 list-disc px-2 pl-6">
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#introduction"
                          >
                            <span className="font-bold">Primary Intake:</span>{" "}
                            Most universities offer a primary intake in
                            September/October.
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            <span className="font-bold">
                              Secondary Intake:{" "}
                            </span>{" "}
                            Some universities also provide a secondary intake in
                            January/February.
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="mr-4 flex flex-col items-center">
                      <div>
                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="h-6 w-6 text-white dark:text-slate-200"
                          >
                            <path d="M12 5l0 14"></path>
                            <path d="M18 13l-6 6"></path>
                            <path d="M6 13l6 6"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                    </div>
                    <div class="pt-1 pb-8">
                      <p class="mb-2 text-xl font-bold text-white dark:text-slate-300">
                        English Language Requirements:
                      </p>
                      <ul class="mt-2 list-disc px-2 pl-6">
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#introduction"
                          >
                            {" "}
                            {/* <span className="font-bold">
                              Foundation Programs:
                            </span>{" "} */}
                        IELTS Score: 5.5 to 6.5 (Undergrad) / 6.0 to 6.5 (Postgrad)
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            {/* <span className="font-bold">
                              Undergraduate Programs:
                            </span>{" "} */}
                            Duolingo Score : score between 120-125
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            {/* <span className="font-bold">
                              Postgraduate Programs:
                            </span>{" "} */}
                            TOEFL Score: 79 to 100
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="mr-4 flex flex-col items-center">
                      <div>
                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-900 bg-blue-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="h-6 w-6 text-white dark:text-slate-200"
                          >
                            <path d="M5 12l5 5l10 -10"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="pt-1 ">
                      {/* <p class="mb-2 text-xl font-bold text-gray-900 dark:text-slate-300">Ready!</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="pl-12">
                <div class="p-4 max-w-xl mx-auto">
                  <div class="flex">
                    <div class="mr-4 flex flex-col items-center">
                      <div>
                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="h-6 w-6 text-white dark:text-slate-200"
                          >
                            <path d="M12 5l0 14"></path>
                            <path d="M18 13l-6 6"></path>
                            <path d="M6 13l6 6"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                    </div>
                    <div class="pt-1 pb-8 ">
                      <p class="mb-2 text-xl font-bold text-white dark:text-dark">
                        Undergraduate (Bachelors):
                      </p>
                      {/* <p class="mb-2 text-xl font-bold text-white dark:text-dark">
                        
                        </p> */}
                      <ul class="mt-2 list-disc px-2 pl-6">
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#introduction"
                          >
                            {/* <span className="font-bold">HSC or A Level:</span>{" "} */}
                            SSC and HSC or equivalent with GPA 3.5+
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            <span className="font-bold"></span> Mandatory IELTS
                            or TOEFL or Duolingo
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            <span className="font-bold"></span> SAT/ACT required
                            for Top Ranked Universities{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="mr-4 flex flex-col items-center">
                      <div>
                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="h-6 w-6 text-white dark:text-slate-200"
                          >
                            <path d="M12 5l0 14"></path>
                            <path d="M18 13l-6 6"></path>
                            <path d="M6 13l6 6"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                    </div>
                    <div class="pt-1 pb-8">
                      <p class="mb-2 text-xl font-bold text-white dark:text-slate-300">
                        Postgraduate (Masters):
                      </p>
                      <ul class="mt-2 list-disc px-2 pl-6">
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#introduction"
                          >
                            {" "}
                            Graduation or equivalent with CGPA 2.75+
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            Mandatory IELTS or TOEFL
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            GMAT or GRE required for Business, Science,
                            Engineering programs
                          </a>
                        </li>
                        <li className="text-white">
                          <a
                            class="block text-white hover:bg-gray-900 px-2 py-1 rounded"
                            href="#getting-started"
                          >
                            Some Institutions waive GMAT or GRE requirements{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <div class="flex">
                      <div class="mr-4 flex flex-col items-center">
                        <div>
                          <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-900">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="h-6 w-6 text-white dark:text-slate-200"
                            >
                              <path d="M12 5l0 14"></path>
                              <path d="M18 13l-6 6"></path>
                              <path d="M6 13l6 6"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                      </div>
                      <div class="pt-1 pb-8">
                        <p class="mb-2 text-xl font-bold text-white dark:text-slate-300">
                          IELTS
                        </p>
                        <p class="text-white dark:text-slate-400">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Morbi sagittis, quam nec venenatis lobortis,
                          mirisus tempus nulla, sed porttitor est nibh at nulla.
                        </p>
                      </div>
                    </div> */}

                  <div class="flex">
                    <div class="mr-4 flex flex-col items-center">
                      <div>
                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-900 bg-blue-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="h-6 w-6 text-white dark:text-slate-200"
                          >
                            <path d="M5 12l5 5l10 -10"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="pt-1 "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <span className="font-bold text-xl text-white">Note:</span>{" "} */}
          <span className="text-white text-lg">
            Edu Solutions Ltd. is committed to facilitating your academic
            aspirations, ensuring personalized guidance and seamless processes
            for a successful study experience in the USA. Choose excellence;
            choose Edu Solutions Ltd. for your journey to success.
          </span>
        </div>
      </div>
    </div>
  );
};

export default EducationalUSA;
